<template>
  <Button variant="ghost" size="icon" class="DialogCloseButton" tabindex="-1">
    <ButtonIcon :icon="mapIcon.close" />
  </Button>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.DialogCloseButton {
  position: absolute;
  top: 16px;
  right: 16px;
  align-self: flex-end;
  width: 100px;
}
</style>
